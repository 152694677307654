import React, { useState, useContext } from "react";
import { Contentful_SiteSettings } from "graphql-types";
import { SiteSettingsContext } from "../contexts/SiteSettingsContext";
import Navbar from "./navbar";
import FloatingMenu from "./floating-menu";
import Search from "./search";

interface Props {
  isNavbarInitiallyTransparent: boolean;
  navbarInitialBgColor?: string;
}

interface State {
  isMenuOpen: boolean;
  isSearchOpen: boolean;
}

function Header(props: Props) {
  const SiteSettings: Contentful_SiteSettings = useContext(SiteSettingsContext);

  const [state, setState] = useState<State>({
    isMenuOpen: false,
    isSearchOpen: false,
  });

  const onClickMenuOpen = () => {
    setState((prevState) => ({
      ...prevState,
      isMenuOpen: true,
    }));
  };

  const onMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      isMenuOpen: false,
    }));
  };

  const onClickSearchOpen = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchOpen: true,
    }));
  };

  const onClickSearchClose = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchOpen: false,
    }));
  };

  return (
    <>
      <header>
        <h2 className="sr-only">Primary Navigation</h2>
        <Navbar
          settings={SiteSettings}
          onClickMenuOpen={onClickMenuOpen}
          onClickSearchOpen={onClickSearchOpen}
          isNavbarInitiallyTransparent={props.isNavbarInitiallyTransparent}
        />
        {state.isMenuOpen && (
          <FloatingMenu settings={SiteSettings} onMenuClose={onMenuClose} onClickSearchOpen={onClickSearchOpen} />
        )}
      </header>
      {state.isSearchOpen && <Search onClickSearchClose={onClickSearchClose} />}
    </>
  );
}

export default Header;
